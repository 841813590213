#pellet-test-list thead
{
}
#pellet-test-list th
{
}
#pellet-test-list span
{

}
#error-row
{
    text-align: center;
}